<template>
  <vuestic-widget v-if="user">
    <template slot="header">
      <div class="w-100 d-flex justify-content-between align-items-center">
        <div class="title">
          <span>Twilio Profiles</span>
        </div>
        <div class="d-flex justify-content-end align-items-center">
          <button class="btn btn-primary btn-sm" @click="onNewProfile">
            <span>New Profile <i class="fa fa-plus"></i></span>
          </button>
        </div>
      </div>
    </template>
    <div class="row">
      <div class="col-md-12">
        <div class="d-flex justify-content-center" v-if="loading">
          <span class="atom-spinner-wrapper">
            <atom-spinner slot="loading" :animation-duration="1500" :size="120" color="rgb(53,120,198)" />
          </span>
        </div>
        <div v-else>
          <div class="d-flex justify-content-end align-items-center mb-3">
            <div class="searchForm pl-2">
              <button type="button" class="btn btn-default btn-primary btn-sm ml-2" @click="refresh()">
                Refresh <i class="fa fa-refresh"></i>
              </button>
            </div>
          </div>
          <template v-if="!hasCampaignRegistered">
            <div v-if="!hasMainProfile && this.tableConfig.total" class="d-block text-center">
              <div class="alert alert-primary text-center">
                In order to let businesses proceed SMS Registration, You need to set Main Profile!
              </div>
            </div>
            <div v-if="hasMainProfile" class="d-block text-center">
              <div class="alert alert-primary text-center">
                You are all set now.&nbsp;&nbsp;<router-link
                  class="font-weight-bold text-primary text-decoration-underline"
                  :to="{ name: 'agency.smsRegistration' }">Click Here</router-link>&nbsp;to submit SMS registration.
              </div>
            </div>
          </template>
          <datatable v-bind="this.tableConfig" class="le-datatable" />
        </div>
      </div>
    </div>

    <vuestic-modal :isOpen="isOpenModalDelete" :hideDefaultActions="true" @cancel="closeModalDelete" :cancelShown="false"
      :okShown="false">
      <span slot="title" class="text-danger font-weight-bold">Delete Profile</span>
      <p>Are you sure you want to delete this profile <strong>{{ profileSelected ? profileSelected.friendlyName : ''
      }}?</strong></p>
      <p>( Only profiles with Draft status can be deleted )</p>
      <div class="row mt-5">
        <div class="col-md-12 d-flex justify-content-center">
          <button @click="onDeleteProfile()" class="btn btn-danger" :disabled="processing">
            <atom-spinner v-if="processing" slot="loading" :animation-duration="1500" :size="14" color="#FFF" />
            <span v-else>Delete</span>
          </button>
        </div>
      </div>
    </vuestic-modal>

    <vuestic-modal :isOpen="isOpenModalSetMain" :hideDefaultActions="true" @cancel="closeModalSetMain"
      :cancelShown="false" :okShown="false">
      <span slot="title" class="text-primary font-weight-bold">Set Main Profile</span>
      <p>Are you sure you want to set this profile <strong>{{ profileSelected ? profileSelected.friendlyName : ''
      }}</strong> as Main Profile?</p>
      <p>Businesses will use this main profile for SMS registration.</p>
      <div class="row mt-5">
        <div class="col-md-12 d-flex justify-content-center">
          <button @click="onSetMainProfile()" class="btn btn-primary" :disabled="processing">
            <atom-spinner v-if="processing" slot="loading" :animation-duration="1500" :size="14" color="#FFF" />
            <span v-else>Set Main Profile</span>
          </button>
        </div>
      </div>
    </vuestic-modal>
    <vuestic-modal :isOpen="isOpenModalMainAlert" :cancelShown="false" :focus="true" @close="okModalAlert"
      @ok="okModalAlert" okText="Got It !">
      <span slot="title" class="text-primary">Alert</span>
      <span class="text-primary font-weight-bold">You first need to add Twilio Primary Profile SID.</span><br /><br />
      <p><i>Please contact <a class="link" href="mailto:support@ringbot.io">support@ringbot.io</a> if you have further
          questions about Twilio profile registration.</i></p>
    </vuestic-modal>
  </vuestic-widget>
</template>
  
<script>
import Vue from 'vue';
import Action from './Actions/Action'
import { mapState } from 'vuex';
import moment from 'moment'
import components from "../../common/tables/comps";

export default {
  components: {
  },
  data() {
    return {
      loading: false,
      processing: false,
      isOpenModalMainAlert: false,
      isOpenModalDelete: false,
      isOpenModalSetMain: false,
      profileSelected: null,
      hasMainProfile: true,
      tableConfig: {
        supportBackup: false,
        supportNested: false,
        HeaderSettings: false,
        tblClass: "table-bordered",
        tblStyle: "color: #666",
        pageSizeOptions: [10, 25, 50, 100],
        columns: (() => {
          return [
            { title: "ID", field: "id", label: "ID", tdClass: "center", sortable: true, visible: false, },
            { title: "Name", field: "friendlyName", label: "Name", thClass: "left", tdClass: "left", sortable: true, visible: true },
            { title: "Email", field: "email", label: "Email", thClass: "left", tdClass: "left", sortable: true, visible: true },
            { title: 'Main', field: 'is_main', sortable: false, visible: true, tdClass: 'center', tdComp: 'TdBoolean', },
            { title: 'Type', field: 'policyType', sortable: false, visible: true, tdClass: 'center' },
            { title: 'Profile Status', field: 'statusStr', sortable: false, visible: true, tdClass: 'center', },
            { title: 'Brand Status', field: 'statusBrandStr', sortable: false, visible: true, tdClass: 'center', },
            { title: "CreatedAt", field: "createdAt", label: "CreatedAt", tdClass: "center", tdComp: "TdTimestamp", sortable: false, visible: true },
            { title: "Actions", tdComp: Action, sortable: false, visible: true },
          ];
        })(),
        data: [],
        total: 0,
        // selection: [],  //if this is present the row selector shows up..
        summary: {},
        query: {},
        // any other staff that you want to pass to dynamic components (thComp / tdComp / nested components)
        xprops: {
          eventbus: new Vue()
        }
      },
    };
  },

  created() {
    this.tableConfig.xprops.eventbus.$on('onView', row => {
      this.$router.push({ name: 'agency.twilioProfile.edit', params: { id: row.sid } });
    })

    this.tableConfig.xprops.eventbus.$on('onMain', row => {
      this.profileSelected = row
      this.isOpenModalSetMain = true
    })

    this.tableConfig.xprops.eventbus.$on('onDelete', row => {
      this.profileSelected = row
      this.isOpenModalDelete = true
    })
  },

  watch: {
    'tableConfig.query': {
      handler() {
        this.loadProfiles();
      },
      deep: true
    }
  },

  computed: {
    user() {
      return this.$store.state.auth.user
    },
    agency() {
      return this.user && this.user.agency
    },
    twPrimaryProfileSid() {
      return this.agency && this.agency.properties && this.agency.properties.twPrimaryProfileSid
    },
    hasCampaignRegistered() {
      return this.agency && this.agency.properties && this.agency.properties.twCampaignSid
    },
  },
  mounted() {
    
  },
  methods: {
    okModalAlert() {
      this.$router.push({ name: 'agency.settings' });
    },
    onNewProfile() {
      if (!this.twPrimaryProfileSid) {
        this.isOpenModalMainAlert = true
      } else {
        this.$router.push({ name: 'agency.twilioProfile.create' });
      }
    },

    refresh() {
      this.loadProfiles()
    },

    loadProfiles() {
      const { query } = this.tableConfig;
      const page = query.offset ? Math.floor(query.offset / query.limit) + 1 : 1;
      const param = {
        page,
        per_page: query.limit,
        order: query.order,
        sort: query.sort
      }
      this.loading = true;
      this.$store.dispatch('twilio/profileList', param)
        .then((data) => {
          this.hasMainProfile = data.hasMain
          this.tableConfig.data = data.data
          this.tableConfig.total = data.meta.total
          this.loading = false
        })
        .catch((errors) => {
          this.loading = false
        })
    },

    closeModalSetMain() {
      this.isOpenModalSetMain = false
      this.profileSelected = undefined
    },

    onSetMainProfile() {
      this.processing = true;
      this.$store
        .dispatch('twilio/profileSetMain', this.profileSelected.sid)
        .then(() => {
          this.processing = false;
          this.$store.dispatch('auth/getProfile')
          this.closeModalSetMain()
          this.loadProfiles();
        })
        .catch((err) => {
          this.processing = false;
        })
    },

    closeModalDelete() {
      this.isOpenModalDelete = false
      this.profileSelected = undefined
    },

    onDeleteProfile() {
      this.processing = true;
      this.$store
        .dispatch('twilio/profileDelete', this.profileSelected.sid)
        .then(() => {
          this.processing = false;
          this.isOpenModalDelete = false;
          this.loadProfiles();
        })
        .catch((err) => {
          this.processing = false;
        })
    },
  },

};
</script>
  
<style lang="scss" scoped></style>
  